import React from "react";
import {useState, useEffect, useContext, createContext} from "react";

import {sendRequest} from "./utils/useApi"
import { useUserContext } from "./authwrapper";

const ProgressContext = createContext([])

export const ProgressContextProvider = ({ children }) => {

  const [user, setUser, setUserLoading, userLoading, apiToken] = useUserContext();

  const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Activity/employeeProgress`;

  const [progressData, setProgressData] = useState()
  const [progressLoading, setProgressLoading ] = useState(true)

  const refreshProgress = () => {
    if (user) {
      sendRequest({
        url: apiURL,
        method:'GET',
        queryParams: {
          employeeId: user.employeeId
        },
        headers: {
          Authorization: "Bearer " + apiToken
        }
      })
      .then((data) => {
        console.log("data", data)
        setProgressData(data)
      }).then((loading) => {
        setProgressLoading(false)
      })
      .catch((err) => console.log(`status: ${err.status} ${err.message}`))
    }
  }
  
  useEffect(() => {
    if (user) {
      refreshProgress();
    }
  }, [user])



const createActivity = (postId, type) => {

  const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Activity`;

    console.log("createActivity", postId, user, type)

  if (user) {
    sendRequest({
      url: apiURL,
      method:'POST',
      body: {
        postId: postId,
        employeeId: user.employeeId,
        type: type,
        completed: false
      }, headers: {
          Authorization: "Bearer " + apiToken
        }
    }).then((res) => console.log("Activity Create"))
    .catch((err) => console.log(`status: ${err.status} ${err.message}`));
  }
  
}

const updateActivity = (postId, type) => {

  const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Activity`;

  console.log("updateActivity", postId, user, type)

  sendRequest({
    url: apiURL,
    method:'GET',
    queryParams: {
      postId: postId,
      employeeId: user.employeeId,
    }, headers: {
      Authorization: "Bearer " + apiToken
    }
  }).then((res) => {
    console.log("activity get data", res)
    sendRequest({
      url: apiURL,
      method:'PUT',
      body: {
        postId: postId,
        employeeId: user.employeeId,
        type: type,
        completed: true,
        id: res.id
      }, headers: {
        Authorization: "Bearer " + apiToken
      }
    }).then((res) => {
      console.log("Activity Put", res)
      refreshProgress();
    })
    .catch((err) => console.log(`status: ${err.status} ${err.message}`))
  });
}
  
  return (
      <>
    {apiToken ?
          <ProgressContext.Provider value={[progressData, createActivity, updateActivity]}>{children}</ProgressContext.Provider>
        :
          <p>Loading</p>
    }
    </>
  )
}

export const useProgressContext = () => {
  const context = useContext(ProgressContext)
  if (context === undefined || context === null) {
    throw new Error(`useProgressContext must be called within ProgressContextProvider`)
  }
  return context
}
