// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/css/normalize.css"
// custom CSS styles
import "./src/css/fonts.css"
//SASS
import "./src/sass/main.scss"

import React from "react"
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./src/authConfig";
import { CustomNavigationClient } from "./src/utils/NavigationClient";
import { AuthWrapper } from "./src/authwrapper"
import {ProfileWrapper} from "./src/profileWrapper";
import { ProgressContextProvider } from "./src/progressWrapper"

const msalInstance = new PublicClientApplication(msalConfig);
export const wrapRootElement = ({ element }) => {

    // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigationClient = new CustomNavigationClient();
    msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
        <AuthWrapper>
            <ProfileWrapper>
                <ProgressContextProvider>
                    {element}
                </ProgressContextProvider>
            </ProfileWrapper>
        </AuthWrapper>
    </MsalProvider>
  )

}
