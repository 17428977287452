/* analytics.js */
import Analytics from 'analytics'
import compassusPlugin from './analytics-plugin'

const analytics = Analytics({
    app: 'compassus-pathways',
    version: 100,
    plugins: [
        compassusPlugin()
    ]
})

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== 'undefined') {
  window.Analytics = analytics
}

/* export for consumption in your components for .track & .identify calls */
export default analytics