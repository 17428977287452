  import { sendRequest } from "./useApi";

  const sendData = async (data) => {
    const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Analytics`;

    const queryData = JSON.stringify(data)

      sendRequest({
        url: apiURL,
        method:'POST',
        body: {
          type: data.type,
          event: queryData
        }
      }).catch((err) => console.log("error: ", err));

  }
  
  /* Export the integration */
  export default function compassusPlugin() {
    // Allow for userland overides of base methods
    return {
      name: 'compassus-pathways-analytics-plugin',
      page: function page(_ref) {
        var payload = _ref.payload;
        sendData(payload);
      },
      track: function track(_ref2) {
        var payload = _ref2.payload;
        sendData(payload);
      },
      identify: function identify(_ref3) {
        // var payload = _ref3.payload;
        // sendData(payload);
      },
      loaded: function loaded() {
        return true;
      }
    }
  }