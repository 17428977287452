
import { BrowserCacheLocation } from "@azure/msal-browser";
//Configuration options for PublicClientApplication and token requests.
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.GATSBY_AAD_CLIENT_ID,
        authority: process.env.GATSBY_AAD_CLIENT_AUTH,
        redirectUri: process.env.GATSBY_AAD_REDIRECT_URI,
        postLogoutRedirectUri: process.env.GATSBY_AAD_LOGOUT_REDIRECT_URI
    },
    cache: {
       cacheLocation: BrowserCacheLocation.LocalStorage
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
//TODO Move api://clientId string to env file
export const loginRequest = {
    scopes: ["User.Read"]
};

export const apiRequest = {
    scopes: [process.env.GATSBY_AAD_API_SCOPE]
}
// // Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/beta/me"
};