import React, { useEffect } from "react"
import FooterLogo from "../../content/assets/footer-logo.svg"
import manageUserPathHistory from "../utils/manageUserPathHistory";

const Layout = ({ isHomePage, children }) => {

  useEffect(() => {
    // set current url in localStorage when navigating/when appropriate
    manageUserPathHistory();
  });

  return (
    <div className="layout" data-is-root-path={isHomePage}>
      <header className="layout__header"></header>
        <main className="layout__inner">
          {children}
        </main>
        <footer className="layout__logo">
          <FooterLogo />
          <div>
            <p>2018 © FC Compassus LLC. All Rights Reserved.</p>
          </div>
        </footer>
    </div>
  )
}

export default Layout
