//  **************************************************************************************
//   - allow users to pick up where they left off by saving last url in localStorage
//   - accepts optional bool param to set or clear localStorage (false param clears storage)
//   - only saves the user's last location when appropriate
//  ***************************************************************************************

const manageUserPathHistory = ( setLastLocation=true ) => {
  const locationPath = typeof window !== 'undefined' ? window.location.pathname : ''
  const locationParms = typeof window !== 'undefined' 
                          ? window.location.search ? window.location.search : ''
                          : ''
  const storageKey = 'compassus_lastLocation'
  const skipLocations = [
    '/', 
    '/search', 
    '/search/', 
    '/terms-of-use', 
    '/terms-of-use/', 
    '/web-app-introductory-video', 
    '/web-app-introductory-video/'
  ]

  if (setLastLocation && !skipLocations.includes(locationPath) && typeof window !== 'undefined') {
    // only set localStorage on appropriate pages
    localStorage.setItem(storageKey, locationPath+locationParms)
  } else if ( setLastLocation === false && typeof window !== 'undefined') {
    // clear localStorage when using back button that directs user to '/'
    localStorage.removeItem(storageKey)
  }
}

export default manageUserPathHistory