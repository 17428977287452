import React, { useEffect, useState, useContext, createContext } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest , apiRequest} from "./authConfig";

import { Loading } from "./components/global-components/loading";
import { ErrorComponent } from "./components/global-components/ErrorComponent";
import { callMsGraph } from "./utils/MsGraphApiCall";
import analytics from "./utils/analytics";

export const UserContext = createContext([])

export const AuthWrapper = ({ children }) => {
    const authRequest = {
        ...loginRequest
    };

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [graphData, setGraphData] = useState(null);
    const [user, setUser] = useState();
    const [userLoading, setUserLoading] = useState(true)
    const [apiToken, setApiToken] = useState('');

    const getApiToken = async () => {

        const response = await instance.acquireTokenSilent({
            ...apiRequest,
          account: account
        })

        return response.accessToken
    }

    useEffect(() => {
        if (account && !graphData && inProgress === InteractionStatus.None) {
            const request = {
                ...loginRequest,
                account: account
            };
            instance.acquireTokenSilent(request).then((response) => {
                callMsGraph(response.accessToken).then(response => {
                    setGraphData(response)
                    setUser({ "employeeId": response.employeeId })
                })
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(request);
                    }
                })
            }).then((response) => {
                getApiToken().then((response) => {
                    setApiToken(response)
                })
            })
            .then(setUserLoading(false))
        }
    }, [account, inProgress, instance, graphData]);

    useEffect(() => {
        if(user){
            analytics.identify(user.employeeId) 
        }
    }, [user])

  return (
    <MsalAuthenticationTemplate 
        interactionType={InteractionType.Redirect} 
        authenticationRequest={authRequest} 
        errorComponent={ErrorComponent} 
        loadingComponent={Loading}
    >
        {!userLoading ? 
            <UserContext.Provider value={[user, setUser, setUserLoading, userLoading, apiToken]}>
                {children}
            </UserContext.Provider>
        :
        <Loading />
        }
    </MsalAuthenticationTemplate>
  );
}

export const useUserContext = () => {
    const context = useContext(UserContext)
    if (context === undefined || context === null) {
      throw new Error(`useUserContext must be called within UserContextProvider`)
    }
    return context
}