import React, {createContext, useContext, useEffect, useState} from "react";
import {useUserContext} from "./authwrapper";
import {sendRequest} from "./utils/useApi";
import TermsOfUse from "./components/page-components/tos";


export const ProfileContext = createContext([]);

export const ProfileWrapper = ({children}) => {
    const [user, setUser, setUserLoading, userLoading, apiToken] = useUserContext();

    const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Profile`;
    const [isOnboarded, setIsOnboarded] = useState(null)
    const [profileLoading, setProfileLoading] = useState(true)

    useEffect(() => {
        if (user) {
            refreshProfile();
        }
    }, [user])

    const refreshProfile = () => {
        sendRequest({
            url: apiURL,
            method:'GET',
            queryParams: {
                employeeId: user.employeeId
            },
            headers: {
                Authorization: "Bearer " + apiToken
            }
        }).then((res) => {
            console.log("res", res)
            if (res.StatusCode === 404) {
                createProfile(user)
            } else {
                console.log("data", res.onboarded)
                setIsOnboarded(res.onboarded)
            }
        }).then((loading) => {
            setProfileLoading(false)
        }).catch((err) => console.log(`status: ${err.status} ${err.message}`))
    }

    const createProfile = () => {

        const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Profile`;
          sendRequest({
            url: apiURL,
            method:'POST',
            body: {
              activeDirectoryId: user.employeeId,
              onboarded: false
            },
            headers: {
                Authorization: "Bearer " + apiToken
            }
          }).then((res) => {
            console.log("Employee login created")
                refreshProfile()
        }).catch((err) => console.log(`status: ${err.status} ${err.message}`));
    }
      
    const updateProfile = () => {
        const apiURL = `${process.env.GATSBY_API_BASE_URL}${process.env.GATSBY_API_PATH}/Profile`;
    
        console.log("createActivity", user)
    
        sendRequest({
        url: apiURL,
        method:'GET',
        queryParams: {
            employeeId: user.employeeId,
        },
        headers: {
            Authorization: "Bearer " + apiToken
        }
        }).then((res) => {
        console.log("Profile get data", res)
        sendRequest({
            url: apiURL,
            method:'PUT',
            body: {
            activeDirectoryId: user.employeeId,
            onboarded: true,
            id: res.id
            },
            headers: {
                Authorization: "Bearer " + apiToken
            }
        }).then((res) => {
            console.log("Profile Put", res)
            refreshProfile();
        })
        .catch((err) => console.log(`status: ${err.status} ${err.message}`))
        });
    }

    return (
        <>
            {isOnboarded !== null ? 
                isOnboarded ?
                    <ProfileContext.Provider value={[updateProfile]}> 
                        {children} 
                    </ProfileContext.Provider>
                :
                    <ProfileContext.Provider value={[updateProfile]}> 
                        <TermsOfUse /> 
                    </ProfileContext.Provider>
            : null}
        </>
    )
}

export const useProfileContext = () => {
    const context = useContext(ProfileContext)
    if (context === undefined || context === null) {
      throw new Error(`useProfileContext must be called within ProfileContextProvider`)
    }
    return context
}